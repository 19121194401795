<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.modelo_instrumento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.modelo_instrumento.titulos.novo')"
      :sem-botao-ok="
        !participanteLogadoConta &&
          !buscarPermissao('ModeloInstrumento', 'Inserir')
      "
      :sem-filtro="!buscarPermissao('ModeloInstrumento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('ModeloInstrumento', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      :disabled="!participanteLogadoConta"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <data-table
      :rows="10"
      :scrollable="true"
      :paginator="false"
      data-key="id"
      :row-hover="true"
      :value="tabela.dados"
      :selection.sync="tabela.selecionados"
      show-gridlines
      class="p-datatable-sm tabela-prime-vue-inherit"
      responsive-layout="scroll"
      row-group-mode="subheader"
      group-rows-by="tipoInstrumento.nome"
      sort-mode="single"
      sort-field="tipoInstrumento.nome"
      :sort-order="1"
      :expandable-row-groups="true"
      :expanded-row-groups.sync="expandedRowGroups"
      @update:sortOrder="atualizarSentidoOrdenacao"
      @update:sortField="atualizarOrdenacaoCampo"
    >
      <template #header>
        <div class="d-flex justify-end">
          <span class="p-input-icon-left ml-100 mr-2">
            <i class="pi pi-search" />
            <InputText
              v-model="filtroGlobal"
              class="p-inputtext-sm"
              placeholder="Filtrar"
              @keydown.enter="filtrarGlobal"
            />
          </span>
          <botao-padrao
            outlined
            tooltip="Colunas"
            @click="abreModalDefineColunas"
          >
            <v-icon>$formatColumns</v-icon>
          </botao-padrao>
        </div>
      </template>
      <Column
        selection-mode="multiple"
        :styles="{ 'max-width': '4%' }"
      />
      <Column
        field="acoes"
        header=""
        :styles="{ 'max-width': '4%' }"
      >
        <template #body="slotProps">
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <dropdown-padrao-item
              v-if="
                participanteLogadoConta &&
                  buscarPermissao('ModeloInstrumento', 'Editar')
              "
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="participanteLogadoConta"
              @click="abrirPontosCalibracao(slotProps.data)"
            >
              {{ $t('modulos.modelo_instrumento.tabela.pontos_de_calibracao') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('ModeloInstrumento', 'Visualizar')"
              @click="abrirVisualizar(slotProps.data)"
            >
              {{ $t('geral.botoes.visualizar') }}
            </dropdown-padrao-item>
          </dropdown-padrao>
        </template>
      </Column>

      <column
        v-for="(col, index) of colunasSelecionadas"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :tipo="col.tipo ?? 'string'"
      />
      <template #groupheader="slotProps">
        <strong>
          {{ $t('modulos.modelo_instrumento.tabela.tipo_de_instrumento') }} :
          {{ slotProps.data.tipoInstrumento.nome }}
          {{
            slotProps.data.numeroItens == 1
              ? `(${slotProps.data.numeroItens} item)`
              : `(${slotProps.data.numeroItens} itens)`
          }}
        </strong>
      </template>
    </data-table>
    <Paginator
      :rows.sync="itensPorpagina"
      :first.sync="paginaAtual"
      :total-records="tabela.quantidadeItens"
      :rows-per-page-options="[
        10, 25, 50, 100, 500]"
      current-page-report-template="({currentPage} de {totalPages})"
      template="JumpToPageInput FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    />
    <DefineColunasModalPrimeVue
      ref="modal-define-colunas"
      :colunas="tabela.colunas"
      :selecionadas="novasColunas"
      @envia-colunas-selecionadas="refazColunasTabela"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ModeloInstrumentoService from '@common/services/cadastros/ModeloInstrumentoService.js';
import Filtro from './filtro.vue';
import DefineColunasModalPrimeVue from '@components/tabelas/DefineColunasModalPrimeVue.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
    DefineColunasModalPrimeVue,
  },
  data() {
    return {
      expandedRowGroups: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'nome',
            text: this.$t('modulos.modelo_instrumento.tabela.nome'),
            sortable: true,
          },
          {
            value: 'marca.nome',
            text: this.$t('modulos.modelo_instrumento.tabela.marca'),
            sortable: true,
            width: 200,
          },
          {
            value: 'qtdFaixas',
            text: this.$t('modulos.modelo_instrumento.tabela.faixas'),
            sortable: true,
            width: 100,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.modelo_instrumento.tabela.descricao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 30,
      },
      filtroAplicado: { filter: '' },
      novasColunas: [],
      colunasSelecionadas: [],
      colunasKey: 0,
      itensPorpagina: 10,
      passo: 0,
      paginaAtual: 1,
      numeroItens: 1,
      filtroGlobal: null,
      watchs: {
        paginaAtual: null,
        itensPorpagina: null,
      },
      permissoes: [],
      colunaOrdenada: '',
    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'ModeloInstrumento', 'Listar');
    this.watchs.paginaAtual && this.watchs.paginaAtual();
    this.watchs.itensPorpagina && this.watchs.itensPorpagina();
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.filtroGlobal = this.filtroAplicado.filter;
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
    this.paginaAtual = (filtros?.paginaAtual - 1) * this.tabela.porPagina || 0;
    this.itensPorpagina = filtros?.porPagina ?? 10;
    this.colunasSelecionadas = filtros?.colunasSelecionadas ?? [];
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.modelo_instrumento.titulos.listagem')
    );
    this.listarRegistros();
    this.tabela.colunas?.forEach((coluna) => {
      coluna.field = coluna.value;
      coluna.header = coluna.text;
    });
    this.colunasSelecionadas = this.colunasSelecionadas.length
      ? this.colunasSelecionadas
      : this.tabela.colunas;
    this.setarWatchs();
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtrarGlobal() {
      this.filtroAplicado.filter = this.filtroGlobal ?? "";
      this.paginaAtual === 0 && this.listarRegistros();
      this.paginaAtual = 0;
    },
    setarWatchs() {
      this.watchs.paginaAtual = this.$watch('paginaAtual', () =>
        this.enviaDadosPaginacao()
      );
      this.watchs.itensPorpagina = this.$watch('itensPorpagina', () =>
        this.enviaDadosPaginacao()
      );
    },
    enviaDadosPaginacao: async function () {
      await this.$nextTick();
      this.passo = this.paginaAtual / this.itensPorpagina + 1;
      this.listarRegistros(this.passo, this.itensPorpagina);
    },
    abreModalDefineColunas() {
      this.$refs['modal-define-colunas'].abrirModal();
    },
    refazColunasTabela(colunas) {
      if (colunas.length) {
        this.novasColunas = [];
        this.tabela.colunas.forEach((col) =>
          colunas.forEach((novaColuna) => {
            if (col.header == novaColuna.coluna) {
              this.novasColunas.push(col);
            }
          })
        );
        this.aoSelecionarColuna(this.novasColunas);
      }
    },
    aoSelecionarColuna(value) {
      this.colunasSelecionadas = this.tabela.colunas.filter((col) =>
        value.includes(col)
      );
      ++this.colunasKey;
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.paginaAtual === 0 && this.listarRegistros();
      this.paginaAtual = 0;
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
        colunasSelecionadas: this.colunasSelecionadas,
      });
      let parametros = {
        page: Math.floor(paginaAtual),
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      ModeloInstrumentoService.listar(parametros)
        .then((res) => {
          // this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;
          this.tabela.dados.map((item) => {
            item.numeroItens = 0;
          });

          let tabela = this.tabela.dados;
          tabela.forEach((itemTabela) => {
            this.tabela.dados.map((item) => {
              if (
                itemTabela.tipoInstrumento.nome == item.tipoInstrumento.nome
              ) {
                item.numeroItens++;
              }
            });
          });

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;

          this.expandTabela();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    expandTabela() {
      this.expandedRowGroups = [];
      this.tabela.dados.filter((p) => {
        this.expandedRowGroups.push(p.tipoInstrumento.nome);
      });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((item) => {
          promises.push(ModeloInstrumentoService.excluir(item.id));
        });
        Promise.all(promises)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(
              this.$t(`modulos.modelo_instrumento.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo() {
      this.$router.push({ name: 'modelo-instrumento-novo' });
    },
    abrirEditar(item) {
      this.$router.push({
        name: 'modelo-instrumento-editar',
        params: { id: item.id },
      });
    },
    abrirPontosCalibracao(item) {
      this.$router.push({
        name: 'modelo-instrumento-pontos',
        params: { id: item.id },
      });
    },
    abrirVisualizar(item) {
      this.$router.push({
        name: 'modelo-instrumento-visualizar',
        params: { id: item.id },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    atualizarSentidoOrdenacao(event) {
      this.ordem = event == 1 ? '' : '-';
      this.novaOrdenacao()
    },
    atualizarOrdenacaoCampo(event) {
      this.campo = event;
    },
    novaOrdenacao() {
      let campoOrdenado = "";
      if (this.campo.includes('.')) campoOrdenado = helpers.formatarColunasComPonto(this.campo);
      else campoOrdenado = helpers.primeiraLetraMaiuscula(this.campo);
      this.colunaOrdenada = this.ordem + campoOrdenado;
      this.listarRegistros()
    }
  },
};
</script>
<style lang="scss" scoped>
.alinhar-direita {
  display: flex;
  justify-content: right;
}
:deep(.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td) {
  padding: 0.001rem 0.5rem !important;
}

.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
:deep(.p-paginator .p-paginator-page-input .p-inputtext) {
  max-width: 4.7rem;
  height: 37px;
  padding-top: 12px;
}
</style>
