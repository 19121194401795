<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-6"
      :label="$t('modulos.modelo_instrumento.filtro.nome')"
      :placeholder="$t('modulos.modelo_instrumento.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.tipoInstrumento"
      class="col-6"
      :label="$t('modulos.modelo_instrumento.filtro.tipo_instrumento')"
      :placeholder="$t('modulos.modelo_instrumento.filtro.tipo_instrumento')"
      em-filtro
    />
    <input-text
      v-model="filtro.marca"
      class="col-6"
      :label="$t('modulos.modelo_instrumento.filtro.marca')"
      :placeholder="$t('modulos.modelo_instrumento.filtro.marca')"
      em-filtro
    />
    <input-text
      v-model="filtro.descricao"
      class="col-6"
      :label="$t('modulos.modelo_instrumento.filtro.descricao')"
      :placeholder="$t('modulos.modelo_instrumento.filtro.descricao')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: { type: Object, default: null },
  },
};
</script>
